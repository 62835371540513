<template>
    <div>
        <h5 class="text-center border p-2">REF #{{receiving.reference}} {{receiving.customer}}</h5>

        <router-link tag="button" class="btn btn-outline-secondary btn-block mb-3"
                     :to="`/receiving/${receiving.id}/general`">
            <font-awesome-icon icon="pencil-alt"/>
            General Review
        </router-link>

        <b-list-group>
            <b-list-group-item class="bg-light font-weight-bold">
                <div class="row">
                    <div class="col-4">SKU <br>LOT</div>
                    <div class="col-4">DESC <br>SERIAL</div>
                    <div class="col-4">&nbsp;<br>QTY</div>
                </div>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between align-items-center cpp"
                               :to="`/receiving/${receiving.id}/item/${item.idmove}`"
                               v-for="(item, index) in items" v-bind:key="index">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-1 text-success">
                            <font-awesome-icon icon="check-circle" v-if="!!+item.checked"/>
                        </div>
                        <div class="col-4">{{item.sku}}</div>
                        <div class="col-7 text-truncate">{{item.description}}</div>
                    </div>
                    <div class="row">
                        <div class="col-4"><b></b>{{item.lot}}</div>
                        <div class="col-4"><b></b>{{item.serial}}</div>
                        <div class="col-4"><b></b>{{item.qty}} {{item.unit | uppercase}}</div>
                    </div>
                </div>
                <font-awesome-icon icon="chevron-right"/>
            </b-list-group-item>
            <li class="list-group-item text-center" v-if="items.length===0">No Content</li>
        </b-list-group>

        <!--<div class="save float">-->
        <!--<font-awesome-icon class="my-float" size="2x" icon="save"/>-->
        <!--</div>-->
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "ReceivingDetail",
        mounted() {
            this.$emit('spinner', true);
            axios.get(`/receivings/${this.$route.params['id']}?key=num`)
                .then((result) => {
                    this.receiving = result.data;
                    axios.get(`/receivings/${this.$route.params['id']}/items`)
                        .then((result) => this.items = result.data)
                        .catch(() => this.$emit('spinner', false));
                    this.$emit('spinner', false)
                })
                .catch(() => this.$emit('spinner', false));
        },
        data() {
            return {
                receiving: {
                    id: null,
                    idcustomer: null,
                    customer: "",
                    user: null,
                    time: null,
                    reference: "",
                    purchase_order: "",
                    notes: "",
                    status: "done",
                    created: "2000-01-01 12:00:00",
                },
                items: [],
            }
        },
        computed: {},
        methods: {}
    }
</script>

<style scoped>

    .float {
        position: fixed;
        width: 60px;
        height: 60px;
        bottom: 40px;
        right: 40px;
        background-color: #007bff;
        color: #FFF;
        border-radius: 50px;
        text-align: center;
        box-shadow: 2px 2px 3px #999;
    }

    .my-float {
        margin-top: 15px;
    }

    .cpp {
        padding: .5rem .5rem !important;
    }
</style>