<template>
    <div>
        <div class="border p-2">
            <h6 class="text-center">SKU {{item.sku}} LOT#{{item.lot}} </h6>
            <h6 class="text-center">{{item.description}}</h6>
        </div>

        <b-form-file class="mt-1" multiple v-model="uploads" accept="image/*" @input="picturesUploaded">
            <template slot="placeholder">
                <font-awesome-icon icon="images"/>
                Upload Images
            </template>
        </b-form-file>
        <!--<p class="text-muted text-center mt-1">-->
        <!--Keep pressed for delete. Touch once for zoom. Picture in gallery, picture saved.-->
        <!--</p>-->
        <div class="gallery row">
            <div class="float-left mt-3 col-6 col-md-4 image-area"
                 v-for="(pic, index) in pictures" v-bind:key="index">
                <img :src="pic.data" class="img-thumbnail img-fluid">
                <button class="remove-image" style="display: inline;" @click.prevent="removeImg(index)">&#215;
                </button>
            </div>
        </div>

        <div class="camera float">
            <label for="camera" class="">
                <font-awesome-icon class="my-float" size="2x" icon="camera"/>
            </label>
            <input ref="camera" id="camera" type="file" accept="image/*" capture="camera" class="float"
                   @change="cameraChange">
        </div>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "ReceivingDetailItemPics",
        mounted() {
            this.$emit('spinner', true);
            axios.get(`/receivings/${this.$route.params['id']}/items/${this.$route.params['idmove']}`)
                .then((result) => this.item = result.data)
                .catch(() => this.$emit('spinner', false));
            axios.get(`/receivings/${this.$route.params['id']}/items/${this.$route.params['idmove']}/picture`)
                .then(result => {
                    this.pictures = result.data;
                    this.$emit('spinner', false);
                })
                .catch(() => this.$emit('spinner', false));
        },
        data() {
            return {
                loading: false,
                uploads: [],
                pictures: [],
                item: {
                    id: null,
                    sku: "",
                    description: "",
                    lot: "",
                    serial: "",
                    qty: "",
                    quants: []
                }
            }
        },
        methods: {
            picturesUploaded() {
                this.uploads.forEach((file) => {
                    const reader = new FileReader();
                    reader.onload = e => this.save(e.target.result);
                    reader.readAsDataURL(file);
                })
            },
            cameraChange(ev) {
                const file = ev.target.files[0];
                const reader = new FileReader();
                reader.onloadstart = () => this.$emit('spinner', true);
                reader.onload = e => this.save(e.target.result);
                // reader.onloadend = () => this.$emit('spinner', false);
                reader.readAsDataURL(file);
            },
            removeImg(index) {
                let idpicture = this.pictures[index].id;
                if (!idpicture) return;

                this.$bvModal.msgBoxConfirm('Are you sure to delete picture?')
                    .then(value => {
                        if (!value) return;
                        this.$emit('spinner', true);
                        axios.delete(`/receivings/${this.$route.params['id']}/items/${this.$route.params['idmove']}/picture/${idpicture}`)
                            .then(() => {
                                this.pictures.splice(index, 1);
                                this.$root.$bvToast.toast('Picture Deleted', this.$toastSuccess);
                                this.$emit('spinner', false);
                            })
                            .catch(() => this.$emit('spinner', false));
                    })
                    .catch(err => {
                        this.$root.$bvToast.toast("ERROR: " + err, this.$toastError);
                    })
            },
            save(data) {
                this.$emit('spinner', true);
                axios.post(`/receivings/${this.$route.params['id']}/items/${this.$route.params['idmove']}/picture`, {'img': data})
                    .then(result => {
                        this.pictures.push({id: result.data.data, data: data});
                        this.$emit('spinner', false);
                        this.$root.$bvToast.toast('Picture Saved', this.$toastSuccess);
                    })
                    .catch(() => {
                        this.pictures.pop();
                        this.$emit('spinner', false);
                    })
            }
        }
    }
</script>

<style scoped>

    /*img {*/
    /*height: 150px!important;*/
    /*}*/

    input[type="file"]#camera {
        display: none;
    }

    .float {
        position: fixed;
        width: 60px;
        height: 60px;
        bottom: 40px;
        right: 40px;
        background-color: #007bff;
        color: #FFF;
        border-radius: 50px;
        text-align: center;
        box-shadow: 2px 2px 3px #999;
    }

    .my-float {
        margin-top: 15px;
    }

    .image-area {
        position: relative;
        width: 50%;
        /*background: #333;*/
    }

    .image-area img {
        max-width: 100%;
        height: auto;
    }

    .remove-image {
        display: none;
        position: absolute;
        top: -5px;
        right: 10px;
        border-radius: 10em;
        padding: 2px 6px 3px;
        text-decoration: none;
        font: 700 21px/20px sans-serif;
        background: #555;
        border: 3px solid #fff;
        color: #FFF;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
        -webkit-transition: background 0.5s;
        transition: background 0.5s;
    }

    .remove-image:hover {
        background: #E54E4E;
        padding: 3px 7px 5px;
        top: -6px;
        right: 11px;
    }

    .remove-image:active {
        background: #E54E4E;
        top: -6px;
        right: 11px;
    }
</style>