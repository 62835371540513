<template>
    <div id="app">
        <div class="update-dialog" v-if="prompt">
            <div class="update-dialog__content">
                A new version is found. Refresh to load it?
            </div>
            <div class="update-dialog__actions">
                <button class="update-dialog__button update-dialog__button--confirm btn btn-success"
                        @click="update"> Update
                </button>
                <button class="update-dialog__button update-dialog__button--cancel btn btn-secondary"
                        @click="prompt = false"> Cancel
                </button>
            </div>
        </div>

        <b-overlay :show="show" rounded="sm" z-index="1040">
            <template #overlay>
                <div class="text-center">
                    <!--<b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>-->
                    <font-awesome-icon icon="spinner" size="lg" flip="horizontal" pulse/>
                    <p id="cancel-label">Please Wait...</p>
                </div>
            </template>

            <nav class="navbar navbar-light bg-primary justify-content-between fixed-top" v-if="isAuth">
                <a class="navbar-brand text-white">
                    <button class="btn" @click.prevent="$router.back()" v-if="$route.path!=='/receiving'">
                        <font-awesome-icon class="text-white" icon="chevron-left"/>
                    </button>
                    <img class="icon" :src="require(`@/assets/icons/${$route.meta.ico}.png`)"/>
                    <span class="font-weight-bold">{{$route.meta.title}}</span>
                </a>
                <!--<form class="form-inline">-->
                <!--<input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">-->
                <!--<button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>-->
                <!--</form>-->

                <div class="btn-group">
                    <button type="button" class="btn btn-outline-light dropdown-toggle" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                        {{session.username}}
                    </button>
                    <div class="dropdown-menu">
                        <button class="dropdown-item" type="button" @click="logout">
                            <font-awesome-icon icon="sign-out-alt"/>
                            Logout
                        </button>
                        <div class="dropdown-divider"></div>
                        <span class="dropdown-item disabled">Ver.{{version}}</span>
                    </div>
                </div>
            </nav>
            <div :class="{'container views': isAuth}">
                <router-view @spinner="changeSpinner"/>
            </div>

        </b-overlay>
    </div>
</template>

<script>
    export default {
        name: "App",
        created() {
            if (this.$workbox) this.$workbox.addEventListener("waiting", () => this.prompt = true);
            this.$nextTick().then(() => this.isAuth = !!localStorage.getItem('idata'))
        },
        updated() {
            this.$nextTick().then(() => this.isAuth = !!localStorage.getItem('idata'))
        },
        data() {
            return {
                prompt: false,
                show: false,
                isAuth: !!localStorage.getItem('idata'),
            };
        },
        computed: {
            session() {
                return JSON.parse(localStorage.getItem('idata'));
            },
            version() {
                return document.title.split('|')[1]
            }
        },
        methods: {
            async update() {
                this.prompt = false;
                await this.$workbox.messageSW({type: "SKIP_WAITING"});
            },
            changeSpinner(val) {
                this.show = val;
            },
            logout() {
                this.$bvToast.toast('Good Bye!', this.$toastSuccess);
                localStorage.removeItem('idata');
                this.$router.push({path: '/'});
                this.isAuth = !!localStorage.getItem('idata');
            },
        },
    }
</script>

<style>
    body {
        min-width: 320px !important;
    }

    #app {
        /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
        /*-webkit-font-smoothing: antialiased;*/
        /*-moz-osx-font-smoothing: grayscale;*/
        /*text-align: center;*/
        /*color: #2c3e50;*/
        color: #4b4b4b;
        max-height: 100vh !important;
        line-height: 1 !important;
    }

    div.views {
        margin-top: 70px !important;
    }

    img.icon {
        height: 34px !important;
    }

    .navbar {
        padding: .5rem .5rem !important;
    }

    @media (max-width: 320px) {
        .navbar-brand {
            font-size: 1rem !important;
        }

        img.icon {
            height: 30px !important;
        }
    }

    .update-dialog {
        position: fixed;
        left: 50%;
        bottom: 64px;
        transform: translateX(-50%);
        border-radius: 4px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        padding: 12px;
        max-width: 576px;
        color: white;
        background-color: #2c3e50;
        text-align: left;
    }

    .update-dialog__actions {
        display: flex;
        margin-top: 8px;
    }

    .update-dialog__button {
        margin-right: 8px;
    }

    .update-dialog__button--confirm {
        margin-left: auto;
    }


</style>
