<template>
    <div>
        <div class="border p-2">
            <h6 class="text-center">SKU {{item.sku}} LOT#{{item.lot}} </h6>
            <h6 class="text-center">{{item.description}}</h6>
        </div>

        <b-form ref="submit" @submit.prevent="save" class="mt-3" novalidate :validated=true>

            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <b-form-group id="input-group-1" label="Mix Size:" label-for="input-1">
                        <b-form-select
                                id="input-1"
                                v-model="selected.maxSide"
                                :options="options"
                        ></b-form-select>
                    </b-form-group>
                </div>
                <div class="col-sm-12 col-md-6">
                    <b-form-group id="input-group-2" label="Seeding:" label-for="input-2">
                        <b-form-select
                                id="input-2"
                                v-model="selected.sedding"
                                :options="options"
                        ></b-form-select>
                    </b-form-group>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <b-form-group id="input-group-3" label="Bad Trimming:" label-for="input-3">
                        <b-form-select
                                id="input-3"
                                v-model="selected.badTrimming"
                                :options="options"
                        ></b-form-select>
                    </b-form-group>
                </div>
                <div class="col-sm-12 col-md-6">
                    <b-form-group id="input-group-4" label="White Butts:" label-for="input-4">
                        <b-form-select
                                id="input-4"
                                v-model="selected.whiteButts"
                                :options="options"
                        ></b-form-select>
                    </b-form-group>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <b-form-group id="input-group-5" label="Curve Tips:" label-for="input-5">
                        <b-form-select
                                id="input-5"
                                v-model="selected.curveTips"
                                :options="options"
                        ></b-form-select>
                    </b-form-group>
                </div>
                <div class="col-sm-12 col-md-6">
                    <b-form-group id="input-group-6" label="Dry Butts:" label-for="input-6">
                        <b-form-select
                                id="input-4"
                                v-model="selected.dryButts"
                                :options="options"
                        ></b-form-select>
                    </b-form-group>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <b-form-group id="input-group-7" label="Discoloration:" label-for="input-7">
                        <b-form-select
                                id="input-7"
                                v-model="selected.discoloration"
                                :options="options"
                        ></b-form-select>
                    </b-form-group>
                </div>
                <div class="col-sm-12 col-md-6">
                    <b-form-group id="input-group-8" label="Flabbiness:" label-for="input-8">
                        <b-form-select
                                id="input-8"
                                v-model="selected.flabbiness"
                                :options="options"
                        ></b-form-select>
                    </b-form-group>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <b-form-group id="input-group-9" label="Misshape:" label-for="input-9">
                        <b-form-select
                                id="input-9"
                                v-model="selected.misshape"
                                :options="options"
                        ></b-form-select>
                    </b-form-group>
                </div>
                <div class="col-sm-12 col-md-6">
                    <b-form-group id="input-group-10" label="Dehydration:" label-for="input-10">
                        <b-form-select
                                id="input-10"
                                v-model="selected.dehydration"
                                :options="options"
                        ></b-form-select>
                    </b-form-group>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <b-form-group id="input-group-10" label="Decay:" label-for="input-10">
                        <b-form-select
                                id="input-10"
                                v-model="selected.decay"
                                :options="options"
                        ></b-form-select>
                    </b-form-group>
                </div>
                <div class="col-sm-12 col-md-6">
                    <b-form-group id="input-group-10" label="Shriveling:" label-for="input-10">
                        <b-form-select
                                id="input-10"
                                v-model="selected.shriveling"
                                :options="options"
                        ></b-form-select>
                    </b-form-group>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <b-form-group id="input-group-10" label="Condensation:" label-for="input-10">
                        <b-form-select
                                id="input-10"
                                v-model="selected.condensation"
                                :options="options"
                        ></b-form-select>
                    </b-form-group>
                </div>
                <div class="col-sm-12 col-md-6">
                    <b-form-group id="input-group-10" label="Dirty:" label-for="input-10">
                        <b-form-select
                                id="input-10"
                                v-model="selected.dirty"
                                :options="options"
                        ></b-form-select>
                    </b-form-group>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <b-form-group id="input-group-10" label="Bruising:" label-for="input-10">
                        <b-form-select
                                id="input-10"
                                v-model="selected.bruising"
                                :options="options"
                        ></b-form-select>
                    </b-form-group>
                </div>
                <div class="col-sm-12 col-md-6">
                    <b-form-group id="input-group-10" label="Insect Damage:" label-for="input-10">
                        <b-form-select
                                id="input-10"
                                v-model="selected.insect_damage"
                                :options="options"
                        ></b-form-select>
                    </b-form-group>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <b-form-group id="input-group-10" label="Spots:" label-for="input-10">
                        <b-form-select
                                id="input-10"
                                v-model="selected.spots"
                                :options="options"
                        ></b-form-select>
                    </b-form-group>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <b-form-group id="input-group-10" label="Temp.Bags:" label-for="input-10">
                        <b-form-input
                                id="input-1"
                                v-model="selected.temp_bags"
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-sm-12 col-md-6">
                    <b-form-group id="input-group-10" label="Temp.Bunches:" label-for="input-10">
                        <b-form-input
                                id="input-1"
                                v-model="selected.temp_bunches"
                        ></b-form-input>
                    </b-form-group>
                </div>
            </div>

            <button type="submit" class="btn btn-primary btn-block mb-4">
                <font-awesome-icon icon="save"/>
                Save Classification
            </button>

        </b-form>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "ReceivingDetailItemClassf",
        mounted() {
            this.$emit('spinner', true);
            axios.get(`/receivings/${this.$route.params['id']}/items/${this.$route.params['idmove']}`)
                .then((result) => this.item = result.data)
                .catch(() => this.$emit('spinner', false));
            axios.get(`/receivings/${this.$route.params['id']}/items/${this.$route.params['idmove']}/classf`)
                .then((result) => {
                    this.selected.maxSide = result.data.max_side;
                    this.selected.sedding = result.data.sedding;
                    this.selected.badTrimming = result.data.bad_trimming;
                    this.selected.whiteButts = result.data.white_butts;
                    this.selected.curveTips = result.data.curve_tips;
                    this.selected.dryButts = result.data.dry_butts;
                    this.selected.discoloration = result.data.discoloration;
                    this.selected.flabbiness = result.data.flabbiness;
                    this.selected.misshape = result.data.misshape;
                    this.selected.dehydration = result.data.dehydration;
                    this.selected.decay = result.data.decay;
                    this.selected.shriveling = result.data.shriveling;
                    this.selected.condensation = result.data.condensation;
                    this.selected.dirty = result.data.dirty;
                    this.selected.bruising = result.data.bruising;
                    this.selected.insect_damage = result.data.insect_damage;
                    this.selected.spots = result.data.spots;
                    this.selected.temp_bags = result.data.temp_bags;
                    this.selected.temp_bunches = result.data.temp_bunches;
                    this.$emit('spinner', false);
                })
                .catch(() => this.$emit('spinner', false));
        },
        methods: {
            save() {
                if (!this.$refs.submit.checkValidity()) return;
                this.$emit('spinner', true);
                axios.post(`/receivings/${this.$route.params['id']}/items/${this.$route.params['idmove']}/classf`, {
                    'max_side': this.selected.maxSide,
                    'sedding': this.selected.sedding,
                    'bad_trimming': this.selected.badTrimming,
                    'white_butts': this.selected.whiteButts,
                    'curve_tips': this.selected.curveTips,
                    'dry_butts': this.selected.dryButts,
                    'discoloration': this.selected.discoloration,
                    'flabbiness': this.selected.flabbiness,
                    'misshape': this.selected.misshape,
                    'dehydration': this.selected.dehydration,
                    'decay': this.selected.decay,
                    'shriveling': this.selected.shriveling,
                    'condensation': this.selected.condensation,
                    'dirty': this.selected.dirty,
                    'bruising': this.selected.bruising,
                    'insect_damage': this.selected.insect_damage,
                    'spots': this.selected.spots,
                    'temp_bags': this.selected.temp_bags,
                    'temp_bunches': this.selected.temp_bunches,
                })
                    .then(() => {
                        this.$root.$bvToast.toast('Inspection Form Saved', this.$toastSuccess);
                        this.$emit('spinner', false);
                        this.$router.back();
                    })
                    .catch(() => this.$emit('spinner', false));
            }
        },
        data() {
            return {
                selected: {
                    maxSide: null,
                    sedding: null,
                    badTrimming: null,
                    whiteButts: null,
                    curveTips: null,
                    dryButts: null,
                    discoloration: null,
                    flabbiness: null,
                    misshape: null,
                    dehydration: null,
                    decay: null,
                    shriveling: null,
                    condensation: null,
                    dirty: null,
                    bruising: null,
                    insect_damage: null,
                    spots: null,
                    temp_bags: null,
                    temp_bunches: null,
                },
                options: [
                    {value: null, text: '-'},//Please select an option
                    {value: 'occ', text: 'Occasional - OCC (5% or less)'},
                    {value: 'few', text: 'Few (10% or less)'},
                    {value: 'some', text: 'Some (11% thru 25%)'},
                    {value: 'many', text: 'Many (26% thru 45%)'},
                    {value: 'aprox', text: 'Approximately - APROX (46% thru 54%)'},
                    {value: 'most', text: 'Mostly (55% thru 89%)'},
                    {value: 'gen', text: 'Generally - GEN (90% or more)'},
                    {value: 'prac', text: 'Practically - PRAC (96% or more)'},
                ],
                receiving: {
                    id: null,
                    idcustomer: null,
                    customer: "",
                    user: null,
                    time: null,
                    reference: "",
                    purchase_order: "",
                    notes: "",
                    status: "done",
                    created: "2000-01-01 12:00:00",
                },
                item: {
                    id: null,
                    sku: "",
                    description: "",
                    lot: "",
                    serial: "",
                    qty: "",
                    quants: []
                }
            }
        },
    }
</script>

<style scoped>

</style>