<template>
    <div>
        <div class="border p-2">
            <!--<h5 class="text-center">#{{receiving.reference}} {{receiving.customer}}</h5>-->
            <h6 class="text-center">SKU {{item.sku}} LOT#{{item.lot}} </h6>
            <h6 class="text-center">{{item.description}}</h6>
        </div>
        <router-link tag="button" class="btn btn-primary btn-lg btn-block mt-5"
                     :to="`/receiving/${receiving.id}/item/${item.id}/pics`">
            <font-awesome-icon icon="camera"/>
            Take Pictures
            <font-awesome-icon icon="check-circle" class="float-right" v-if="status.images>0"/>
        </router-link>

        <router-link tag="button" class="btn btn-primary btn-lg btn-block mt-5"
                     :to="`/receiving/${receiving.id}/item/${item.id}/classf`">
            <font-awesome-icon icon="clipboard-check"/>
            Fill USDA Classf.
            <font-awesome-icon icon="check-circle" class="float-right" v-if="status.moves>0"/>
        </router-link>


    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "ReceivingDetailItem",
        mounted() {
            this.$emit('spinner', true);
            axios.get(`/receivings/${this.$route.params['id']}`)
                .then((result) => this.receiving = result.data)
                .catch(() => this.$emit('spinner', false));
            axios.get(`/receivings/${this.$route.params['id']}/status/${this.$route.params['idmove']}`)
                .then((result) => {
                    this.status = result.data;
                    this.$emit('spinner', false);
                });
            axios.get(`/receivings/${this.$route.params['id']}/items/${this.$route.params['idmove']}`)
                .then((result) => {
                    this.item = result.data;
                    this.$emit('spinner', false);
                })
                .catch(() => this.$emit('spinner', false));
        },
        data() {
            return {
                status: {
                    moves: 0,
                    images: 0
                },
                receiving: {
                    id: null,
                    idcustomer: null,
                    customer: "",
                    user: null,
                    time: null,
                    reference: "",
                    purchase_order: "",
                    notes: "",
                    status: "done",
                    created: "2000-01-01 12:00:00",
                },
                item: {
                    id: null,
                    sku: "",
                    description: "",
                    lot: "",
                    serial: "",
                    qty: "",
                    quants: []
                }
            }
        },
    }
</script>

<style scoped>

</style>