import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import ReceivingList from '../views/receivings/List'
import ReceivingDetail from '../views/receivings/Detail'
import ReceivingDetailGeneral from '../views/receivings/DetailGeneral'
import ReceivingDetailItem from '../views/receivings/DetailItem'
import ReceivingDetailItemClassf from '../views/receivings/DetailItemClassf'
import ReceivingDetailItemPics from '../views/receivings/DetailItemPics'
import Login from '../views/Login'

Vue.use(VueRouter);

const routes = [{
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
        title: '',
        ico: 'home',
    }
}, {
    path: '/receiving',
    name: 'ReceivingList',
    component: ReceivingList,
    meta: {
        title: 'Receivings',
        ico: 'inbox',
    }
}, {
    path: '/receiving/:id',
    name: 'ReceivingDetail',
    component: ReceivingDetail,
    meta: {
        title: 'Receiving Detail',
        ico: 'detail',
    }
}, {
    path: '/receiving/:id/general',
    name: 'ReceivingDetailGeneral',
    component: ReceivingDetailGeneral,
    meta: {
        title: 'Receiving Review',
        ico: 'pencil',
    }
}, {
    path: '/receiving/:id/item/:idmove',
    name: 'ReceivingDetailItem',
    component: ReceivingDetailItem,
    meta: {
        title: 'Lot Inspection',
        ico: 'spy',
    }
}, {
    path: '/receiving/:id/item/:idmove/classf',
    name: 'ReceivingDetailItemClassf',
    component: ReceivingDetailItemClassf,
    meta: {
        title: 'Inspection USDA',
        ico: 'inspection',
    }
}, {
    path: '/receiving/:id/item/:idmove/pics',
    name: 'ReceivingDetailItemPics',
    component: ReceivingDetailItemPics,
    meta: {
        title: 'Lot Pictures',
        ico: 'camera',
    }
}, {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
}];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router
