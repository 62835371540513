<template>
    <div>
        <h5 class="text-center border p-2">{{receiving.reference}} {{receiving.customer}}</h5>

        <b-form ref="submit" @submit.prevent="save" class="mt-3" novalidate :validated=true>

            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <b-form-group id="input-group-1" label="Grading:" label-for="input-1">
                        <b-form-select
                                id="input-1"
                                v-model="selected.grading"
                                :options="optionsGrading"
                                required
                        ></b-form-select>
                    </b-form-group>
                </div>
                <div class="col-sm-12 col-md-6">
                    <b-form-group id="textarea-1" label="Final Resolution:" label-for="textarea-1">
                        <b-form-textarea
                                id="textarea-1"
                                v-model="selected.resolution"
                                placeholder=""
                                rows="4"
                                max-rows="8"
                        ></b-form-textarea>
                    </b-form-group>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-md-4">
                    <b-form-group id="input-group-1" label="Freezing:" label-for="input-1">
                        <b-form-input
                                id="input-1"
                                v-model="selected.freezing"
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-sm-12 col-md-4">
                    <b-form-group id="input-group-1" label="Tags:" label-for="input-1">
                        <b-form-input
                                id="input-1"
                                v-model="selected.tags"
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-sm-12 col-md-4">
                    <b-form-group id="input-group-1" label="Temp:" label-for="input-1">
                        <b-form-input
                                id="input-1"
                                v-model="selected.temp"
                        ></b-form-input>
                    </b-form-group>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-md-4">
                    <b-form-group id="input-group-1">
                        <b-form-checkbox
                                switch size="lg"
                                id="chktclean"
                                v-model="selected.trailer_is_clean"
                        >Trailer is clean
                        </b-form-checkbox>
                    </b-form-group>
                </div>
                <div class="col-sm-12 col-md-4">
                    <b-form-group id="input-group-1">
                        <b-form-checkbox
                                switch size="lg"
                                id="chkdebris"
                                v-model="selected.trailer_is_free_debris"
                        >Trailer is free from debris
                        </b-form-checkbox>
                    </b-form-group>
                </div>
                <div class="col-sm-12 col-md-4">
                    <b-form-group id="input-group-1">
                        <b-form-checkbox
                                switch size="lg"
                                id="chkodors"
                                v-model="selected.trailer_is_free_odors"
                        >Trailer is free from strong foul odors
                        </b-form-checkbox>
                    </b-form-group>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <b-form-group id="input-group-1">
                        <b-form-checkbox
                                switch size="lg"
                                id="chkpest"
                                v-model="selected.trailer_is_free_pest"
                        >Trailer is free from pest and insects
                        </b-form-checkbox>
                    </b-form-group>
                </div>
                <div class="col-sm-12 col-md-6">
                    <b-form-group id="input-group-1">
                        <b-form-checkbox
                                switch size="lg"
                                id="chkrefri"
                                v-model="selected.trailer_refrigeration"
                        >Trailer refrigeration unit properly operating
                        </b-form-checkbox>
                    </b-form-group>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-md-12">
                    <b-form-group id="textarea-2" label="Comments:" label-for="textarea-2">
                        <b-form-textarea
                                id="textarea-2"
                                v-model="selected.comments"
                                placeholder=""
                                rows="3"
                                max-rows="8"
                        ></b-form-textarea>
                    </b-form-group>
                </div>
            </div>

            <button type="submit" class="btn btn-primary btn-block">
                <font-awesome-icon icon="save"/>
                Save Review
            </button>
        </b-form>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "ReceivingDetailGeneral",
        mounted() {
            this.$emit('spinner', true);
            axios.get(`/receivings/${this.$route.params['id']}`)
                .then((result) => this.receiving = result.data)
                .catch(() => this.$emit('spinner', false));
            axios.get(`/receivings/${this.$route.params['id']}/general`)
                .then((result) => {
                    this.selected.grading = result.data.grading;
                    this.selected.resolution = result.data.resolution;
                    this.selected.comments = result.data.comments;
                    this.selected.freezing = result.data.freezing;
                    this.selected.tags = result.data.tags;
                    this.selected.temp = result.data.temp;
                    this.selected.trailer_is_clean = Boolean(parseInt(result.data.trailer_is_clean));
                    this.selected.trailer_is_free_debris = Boolean(parseInt(result.data.trailer_is_free_debris));
                    this.selected.trailer_is_free_odors = Boolean(parseInt(result.data.trailer_is_free_odors));
                    this.selected.trailer_is_free_pest = Boolean(parseInt(result.data.trailer_is_free_pest));
                    this.selected.trailer_refrigeration = Boolean(parseInt(result.data.trailer_refrigeration));
                    this.$emit('spinner', false);
                })
                .catch(() => this.$emit('spinner', false));
        },
        data() {
            return {
                selected: {
                    grading: null,
                    resolution: "",
                    comments: "",
                    freezing: "",
                    tags: "",
                    temp: "",
                    trailer_is_clean: true,
                    trailer_is_free_debris: true,
                    trailer_is_free_odors: true,
                    trailer_is_free_pest: true,
                    trailer_refrigeration: true,
                },
                optionsGrading: [
                    {value: null, text: '-'},//Please select an option
                    {value: 'excellent', text: 'Excellent - 1'},
                    {value: 'good', text: 'Good - 1.5'},
                    {value: 'reasonable', text: 'Reasonable - 2'},
                    {value: 'porr', text: 'Poor - 2.5 to 3.0 '},

                ],
                receiving: {
                    id: null,
                    idcustomer: null,
                    customer: "",
                    user: null,
                    time: null,
                    reference: "",
                    purchase_order: "",
                    notes: "",
                    status: "done",
                    created: "2000-01-01 12:00:00",
                },
                item: {
                    id: null,
                    sku: "",
                    description: "",
                    lot: "",
                    serial: "",
                    qty: "",
                    quants: []
                }
            }
        },
        methods: {
            save() {
                if (!this.$refs.submit.checkValidity()) return;
                this.$emit('spinner', true);
                axios.post(`/receivings/${this.$route.params['id']}/general`, {
                    'grading': this.selected.grading,
                    'resolution': this.selected.resolution,
                    'comments': this.selected.comments,
                    'freezing': this.selected.freezing,
                    'tags': this.selected.tags,
                    'temp': this.selected.temp,
                    'trailer_is_clean': Number(this.selected.trailer_is_clean),
                    'trailer_is_free_debris': Number(this.selected.trailer_is_free_debris),
                    'trailer_is_free_odors': Number(this.selected.trailer_is_free_odors),
                    'trailer_is_free_pest': Number(this.selected.trailer_is_free_pest),
                    'trailer_refrigeration': Number(this.selected.trailer_refrigeration),
                })
                    .then(() => {
                        this.$root.$bvToast.toast('Review Saved', this.$toastSuccess);
                        this.$router.back();
                        this.$emit('spinner', false);
                    })
                    .catch(() => this.$emit('spinner', false));
            }
        },
    }
</script>

<style>
    label  {
        color: #343a40 !important;
    }

</style>